import styled, { css } from 'styled-components';

import { TextAlign, TextColor, TextSize, TextTransform, TextWeight } from '@Helpers/types/text';

interface TextProps {
  $weight: TextWeight;
  $size: TextSize;
  $mobileSize?: TextSize;
  $color: TextColor;
  $textAlign: TextAlign;
  onClick?: () => void;
  $textTransform: TextTransform;
  $isTruncated: boolean;
}

export const Root = styled.p<TextProps>`
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text[props.$weight][props.$size].fontSize};
  font-weight: ${(props) => props.theme.font.text[props.$weight][props.$size].fontWeight};
  line-height: ${(props) => props.theme.font.text[props.$weight][props.$size].lineHeight};
  letter-spacing: ${(props) => props.theme.font.text[props.$weight][props.$size].letterSpacing};
  color: ${(props) => props.theme.palette.text[props.$color]};

  transition: color ${(props) => props.theme.transition.theme};

  text-align: ${(props) => props.$textAlign};
  text-transform: ${(props) => props.$textTransform};

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    font-size: ${(props) => props.theme.font.text[props.$weight][props.$mobileSize ?? props.$size].fontSize};
  }

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
  ${(props) =>
    props.$isTruncated &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
    b,
    strong {
    font-weight: bold;
  }
`;
